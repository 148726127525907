import React from 'react';
import { Grid, withStyles } from "@material-ui/core";
import styles from "./styles-jss";
import classNames from 'classnames';

function Footer(props){
    const {classes} = props;
    return(
        <Grid container justifyContent='center' alignItems='flex-end' className={classes.footer} spacing={0}>
          <Grid className={classes.rightFooter} item xs={12} sm={12} md={3}>
          <div className={classNames(classes.labelColor, classes.mobileOnly)}>שירות הענקת מתנות כרוך בעמלה ובכפוף <a target="_blank" href="https://app.wiwi.co.il/terms">לתנאי השימוש</a></div>
            <div className={classes.labelColor} style={{paddingRight:'25px'}}>
              תמיכה טכנית <a style={{color:'white', textDecoration:'none'}} href="tel:0539242324">053-924-2324</a>
            </div>
          </Grid>
          <Grid className={classes.centerFooter} item xs={12} sm={12} md={6}>
            <div className={classNames(classes.labelColor, classes.desktopOnly)}>שירות הענקת מתנות כרוך בעמלה ובכפוף <a target="_blank" href="https://app.wiwi.co.il/terms">לתנאי השימוש</a></div>
            <div className={classes.labelColor}>תתכן תוספת עמלה בתשלום דרך ביט</div>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: '40px' }}>
              <h3  className={classes.labelColor} style={{ marginLeft:'25px' }}>התשלום מאובטח בתקן</h3>
              <img width="100px" height="40px" src="/images/pci-logo.png" />
            </div>
          </Grid>
          <Grid className={classes.leftFooter} item xs={12} sm={12} md={3}>
            <div style={{ paddingLeft: '25px' }}>
              {/* <img width="100px" src="/images/payme-logo.png" /> */}
              <img width="150px" height="35px" src="/images/payment-icons.png" />
            </div>
          </Grid>
        </Grid>
    )
}

export default withStyles(styles)(Footer)