import React, { useState, useEffect } from "react";
import {
  Grid, TextField, Card, Button, CardContent, CardActions,
  Typography, Slide, Slider, FormControl, InputLabel, Dialog
} from "@material-ui/core";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import { API } from "../api/index";
import { DataService } from "../api/dataService";
import Decoration from "../components/layout/decoration";
import styles from "./styles-jss";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as yup from 'yup'
import { useFormik } from 'formik'
import LoadingButton from '../components/ui/loadingButton'
import InputMask from "react-input-mask";
import Footer from '../components/layout/footer';
import { Hidden } from "@material-ui/core";
const Wish = (props) => {
  const { classes } = props;
  let { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0)
  const [exited, setExited] = useState(true)
  const animation = 1000;


  useEffect(() => {
    async function GetData() {
      var res = await DataService.get(`${API.index.getEvent}?hash=${token}&past=${true}`);
      if (res.data.success) {
        const d = res.data.data;
        setLoading(false);
        setData(d);
        formik.setFieldValue('eventId', d.id)
      } else {
        window.location.replace("https://www.wiwi.co.il");
      }

    }
    GetData();
    const params = new URLSearchParams(window.location.search);
    if (params.has("name"))
      formik.setFieldValue('name', params.get('name'))
    if (params.has("phone"))
      formik.setFieldValue('phone', params.get("phone"))
  }, [token]);

  function handleNextStep() {
    if (currentStep == 0) {
      formik.submitForm();
      return;
    }

    setExited(false);
    setCurrentStep(currentStep + 1);
  }

  const validationSchema = yup.object({
    name: yup.string().required("אנחנו צריכים לדעת מי אתם 😥"),
    phone: yup.string().required("מה המספר שלך? מבטיחים לא להטריד 😥"),
    message: yup.string().required("איפה הברכה? לשם כך התכנסנו 😉"),

  });
  const formik = useFormik({
    initialValues: {
      eventId: 0,
      name: '',
      phone: '',
      message: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      var res = await DataService.post(API.index.postWish, values)

      if (res.data.success) {
        setCurrentStep(1)
      } else if (res.data.errorCode === 429) {
        formik.setFieldError('message', "נסו שנית מאוחר יותר 🤩");
      }
    }
  });

  if (loading) {
    return (
      <div>
        <Dialog PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }} style={{ color: "#fff", zIndex: 99999, }} open={true}>
          <img height="150" src='/images/loader.gif' />
        </Dialog>
      </div>)
  }
 
  return (
    <div>

      <Grid container className={classes.container} justifyContent="center" alignContent="center">
        {/* <Grid item xs={12} md={3} lg={4}></Grid> */}
        <Grid item xs={12} style={{ maxWidth: '650px' }}>
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <br />
              <div className={classes.logoContainer}>
                <img width="100%" src="/images/wiwi-logo.svg" />
              </div>
              <br />
              <Slide direction="right" onExited={() => setExited(true)} timeout={animation} in={currentStep == 0 && exited == true} mountOnEnter unmountOnExit>
                <div style={{ minHeight: '150px' }}>
                  <Typography variant="h4" className={classes.title} color="primary" align="center">{data?.name}</Typography>
                  <Typography variant="h6" style={{ marginBottom: '20px' }} gutterBottom color="secondary" align="center">🎉💕 ניתן לרשום ברכה כאן למטה 💕🎉</Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="איך קוראים לך?"
                          name="name"
                          variant="outlined"
                          size="small"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={formik.touched.name && formik.errors.name} />
                      </Grid>

                      <Grid item xs={12}>
                        <InputMask fullWidth
                          label="מספר טלפון"
                          name="phone"
                          mask="9999999999"
                          variant="outlined"
                          size="small"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          error={formik.touched.phone && Boolean(formik.errors.phone)}
                          helperText={formik.touched.phone && formik.errors.phone}
                        >
                          {(inputProps) => (
                            <TextField  {...inputProps} />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth label="הברכה המרגשת שלכם 🤩"
                          name="message"
                          multiline
                          rows={5}
                          variant="outlined"
                          size="small"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          error={formik.touched.message && Boolean(formik.errors.message)}
                          helperText={formik.touched.message && formik.errors.message}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Slide>
              <Slide direction="right" onExited={() => setExited(true)} timeout={animation} in={currentStep == 1 && exited == true} mountOnEnter unmountOnExit>
                <div style={{ textAlign: 'center' }}>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '70px', marginBottom: '70px' }}>
                      <div className={classes.heartShape}>
                      </div>
                      <br />
                    </div>
                    <Typography variant="h5" gutterBottom color="secondary" align="center">וואוו.... התרגשנו עד דמעות 🤗</Typography>
                    <Typography variant="h5" gutterBottom color="primary" align="center">תודה רבה! העברנו את הברכה לבעלי האירוע</Typography>
                  </div>
                </div>

              </Slide>




            </CardContent>
            <CardActions style={{ justifyContent: 'center', flexDirection: 'column', display: currentStep == 5 && 'none' }}>
              {currentStep != 1 &&
                <LoadingButton disabled={formik.isSubmitting} loading={formik.isSubmitting} onClick={() => handleNextStep()}
                  classes={{ root: classNames(classes.gradientBtn, classes.margin) }} size='large'>שיגור הברכה ✈️</LoadingButton>
              }

            </CardActions>

          </Card>
          <Decoration
            showFooter={false}
            mode={'light'}
            gradient={true}
            decoration={true}
            bgPosition={'half'}
            horizontalMenu={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Wish);
