//import { Result, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { API } from "../api/index";
import { DataService } from "../api/dataService";
import { useParams } from "react-router-dom";
import NotFound from "./404";
import { Dialog, Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from "./styles-jss";
import Decoration from "../components/layout/decoration";

const Success = (props) => {
  const { classes } = props
  let { token, name } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function GetData() {
      var hash = token
      if (token.length > 2) {
        hash = token.slice(2)
      }
      var res = await DataService.get(
        API.index.getByToken + "?token=" + hash
      );
      if (res.data.success) {
        setData(res.data.data);
      } else {
        window.location.replace("https://www.wiwi.co.il");
      }
    }
    GetData();
  }, [token]);


  function OpenLink(link) {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      setTimeout(() => {
        window.open(link, '_top');
      })
    } else {
      window.open(link, '_self');
    }
  }

  if (data != null) {
    return (
      <Grid container className={classes.container} justifyContent="center" alignContent="center">
        <Grid item xs={12} style={{ maxWidth: '650px' }}>
          <Card variant="outlined" className={classes.card}>
            <CardContent style={{ textAlign: 'center' }}>
              <br />
              <div className={classes.logoContainer}>
                <img width="100%" src="/images/wiwi-logo.svg" />
              </div>
              <br />
              <Typography variant="h5" gutterBottom color="secondary" align="center">{data?.event}</Typography>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '70px', marginBottom: '70px' }}>
                <div className={classes.heartShape}>
                </div>
                <br />
              </div>
              <Typography variant="h5" gutterBottom color="secondary" align="center">תודה רבה!</Typography>
              <Typography variant="h5" gutterBottom color="primary" align="center">{name ? name : data?.name}</Typography>
              <Button variant="contained" color="primary" style={{ backgroundColor: '#46d946' }} onClick={() => OpenLink(`/b/b/${data?.eventHash}`)}>כתיבת ברכה</Button>
            </CardContent>


          </Card>
          <Decoration
            mode={'light'}
            gradient={true}
            decoration={true}
            bgPosition={'half'}
            horizontalMenu={false}
          />
        </Grid>
      </Grid>

    );
  }

  return (
    <Dialog PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }} style={{ color: "#fff", zIndex: 99999, }} open={true}>
      <img height="150" src='/images/loader.gif' />
    </Dialog>
  )

};

export default withStyles(styles)(Success);
